/*global $*/
/*eslint no-undef: "error"*/
export default class Offer {
    classItem = 'offer--item'

    classButton = 'offer--button'
    classButtonActive = 'offer---active'

    classBlocks = 'offer---blocks'
    classBlocksActive = 'offer-blocks---active'

    classElement = 'offer--element'
    classElementHidden = 'offer-block---hidden'
    // classElementActive = 'offer-block__props---active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).not(`[data-block='${block}']`).removeClass(self.classButtonActive)
            $(this).toggleClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classElement}`).addClass(self.classElementHidden)

            if ($(this).hasClass(self.classButtonActive)) {
                $(this).closest(`.${self.classItem}`).find(`.${self.classBlocks}`).addClass(self.classBlocksActive)

                $(this).closest(`.${self.classItem}`).find(`.${self.classElement}[data-block='${block}']`).removeClass(self.classElementHidden)
            } else {
                $(this).closest(`.${self.classItem}`).find(`.${self.classBlocks}`).removeClass(self.classBlocksActive)
            }
        })
    }
}
