/*global $*/
/*eslint no-undef: "error"*/
export default class OfferToggle {
    classButton = 'offer-toggle--button'
    classActive = 'offer-block---toggle-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classActive)
        })
    }
}
